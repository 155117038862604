<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Attendance</h2>
      <r-menu-tab :level="2" :showed-menus="3"></r-menu-tab>
    </template>
    <template #action-bar>
      <div class="level relative" v-if="currentUser">
        <div class="level-left">
          <!-- datepicker for table -->
          <b-datepicker
            v-model="selectedDates"
            :mobile-native="false"
            class="attendance-datepicker"
            range
            v-if="isViewedAsTable"
          >
            <template v-slot:trigger>
              <b-button
                icon-left="calendar-today"
                class="attendanceteam-calendar-btn"
              >
                {{ isViewedAsTable ? getRangeDateTitle : '' }}
              </b-button>
            </template>
          </b-datepicker>

          <!-- datepicker for calendar view -->
          <b-datepicker
            v-model="selectedDateForWeek"
            :mobile-native="false"
            class="attendance-datepicker"
            v-if="!isViewedAsTable"
          >
            <template v-slot:trigger>
              <b-button class="attendanceteam-calendar-btn">
                {{ isViewedAsTable ? getRangeDateTitle : '' }}
              </b-button>
            </template>
          </b-datepicker>
        </div>

        <div
          class="level-right transition-all"
          @click="toggleView(false)"
          v-if="isViewedAsTable"
        >
          <b-icon icon="menu" class="attendance-btn-icon"></b-icon>
        </div>

        <div
          class="level-right transition-all attendance-autocomplete"
          v-if="!isViewedAsTable && teamMemberDataName.length > 1"
        >
          <b-autocomplete
            v-model="teamMemberName"
            open-on-focus
            icon-right="chevron-down"
            :data="filterTeamMemberDataName"
            clearable
            @select="(option) => (selected = option)"
          ></b-autocomplete>
        </div>

        <div
          class="level-right transition-all"
          @click="toggleView(true)"
          v-if="!isViewedAsTable"
        >
          <b-icon icon="calendar-weekend" class="attendance-btn-icon"></b-icon>
        </div>
      </div>
    </template>
    <template #page-content>
      <b-modal v-model="isComponentModalActive" full-screen can-cancel>
        <ActivityDetail
          v-if="selectedAttendance"
          :date="selectedAttendance.date"
          :user-name="selectedUserName"
          :user-id="selectedUserId"
          is-self
        />
      </b-modal>

      <div>
        <!-- Table My Attendance View -->
        <div class="columns is-multiline" v-if="isViewedAsTable">
          <b-table
            :data="dataMyAttendance"
            class="attendance-table transition-all"
            :sticky-header="stickyHeaders"
            :selected="selectedAttendance"
            hoverable
          >
            <b-table-column
              field="date"
              label="Date"
              cell-class="universe-table-column-date"
              v-slot="props"
            >
              {{
                props.row.date
                  ? formatDate(props.row.date)
                  : formatDate(props.row)
              }}
            </b-table-column>

            <b-table-column
              field="clock_in"
              label="Clock In"
              centered
              v-slot="props"
            >
              {{
                props.row.firstCheckIn
                  ? formatTime(props.row.firstCheckIn)
                  : '-'
              }}
            </b-table-column>

            <b-table-column
              field="clock_out"
              label="Clock Out"
              centered
              v-slot="props"
            >
              {{
                props.row.lastCheckOut
                  ? formatTime(props.row.lastCheckOut)
                  : '-'
              }}
            </b-table-column>

            <b-table-column
              field="status"
              label="Status"
              centered
              v-slot="props"
            >
              <span
                class="attendance-status-wfo"
                :class="
                  props.row.isRemote
                    ? 'attendance-status-wfh'
                    : 'attendance-status-wf0'
                "
              >
                {{ props.row.isRemote ? 'WFH' : 'WFO' }}
              </span>
            </b-table-column>

            <b-table-column
              field="duration"
              label="Duration"
              centered
              v-slot="props"
            >
              {{ props.row.durationPerDay ? props.row.durationPerDay : '-' }}
            </b-table-column>

            <b-table-column
              field="action"
              label="Action"
              centered
              v-slot="props"
            >
              <b-icon
                @click.native="openModal(props.row)"
                icon="eye"
                custom-size="mdi-18px"
              />
            </b-table-column>

            <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
          </b-table>
        </div>

        <!-- Time Grid Calendar Attendance View -->
        <div
          class="columns is-multiline calendar-attendance-wrapper"
          v-if="!isViewedAsTable"
        >
          <FullCalendar
            :options="calendarOptions"
            ref="fullCalendar"
            class="calendar-attendance-weekly"
          >
            <template v-slot:dayHeaderContent="arg">
              <p class="calendar-header-date">
                {{ formatHeaderDate(arg.date) }}
              </p>
              <p class="calendar-header-day">
                {{ formatHeaderDay(arg.date) }}
              </p>
            </template>
            <template v-slot:eventContent="arg">
              <div
                :class="
                  arg.event.extendedProps && arg.event.extendedProps.status
                    ? 'attendance-event-wfh'
                    : 'attendance-event-wfo'
                "
              >
                <p class="duration-text">
                  {{ getTimeRange(arg.event.start, arg.event.end) }}
                </p>
                <p>
                  {{
                    arg.event.extendedProps &&
                    arg.event.extendedProps.location &&
                    arg.event.extendedProps.location.slice(0, 25) + '...'
                  }}
                </p>
                <p>
                  {{ convertTimeToDuration(arg.event.start, arg.event.end) }}
                </p>
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import moment from 'moment-timezone'
import { mapActions, mapGetters } from 'vuex'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import ActivityDetail from './ActivityDetail'

export default {
  name: 'AttendanceTeam',
  components: {
    FullCalendar,
    ActivityDetail,
  },
  data() {
    return {
      isComponentModalActive: false,
      selectedDates: [],
      dataMyAttendance: [],
      stickyHeaders: true,
      displayedDateRange: '',
      startDate: '',
      endDate: '',
      isViewedAsTable: true,
      startWeek: 123,
      endWeek: null,
      combinedDateForWeek: null,
      selectedDateForWeek: null,
      teamMemberName: 'My Data',
      teamMemberData: [],
      singleMemberData: [],
      teamMemberDataName: ['My Data'],
      allMemberData: [],
      selectedAttendance: null,
      selectedUserId: null,
      selectedUserName: null,
      isSideBarAttendanceOpen: false,
      showRow: {},
      showDetail: [],

      // API methods for FullCalendar
      calendarOptions: {
        plugins: [timeGridPlugin],
        initialView: 'timeGridWeek',
        scrollTime: '08:00',
        height: 'calc(100vh - 100px)',
        allDaySlot: false,
        // method that will be triggered when there is an event
        datesSet: (dateInfo) => {
          this.startWeek = moment(dateInfo.startStr).format('YYYY-MM-DD')
          this.endWeek = moment(dateInfo.endStr).format('YYYY-MM-DD')
          this.combinedDateForWeek = `${this.startWeek}|${this.endWeek}`
        },
        dayHeaderClassNames: 'calendar-column-header',
        dayCellClassNames: 'calendar-timegrid-cell',
        firstDay: 0, // to define which day is the start of the week
        dayHeaderFormat: {
          weekday: 'long',
          day: 'numeric',
        },
        eventTimeFormat: {
          minute: '2-digit',
          hour: 'numeric',
          meridiem: true,
        },
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: 'true',
        },
        // eventColor: '#F6F6F6',
        eventTextColor: '#4B4B4B',
        events: [],
      },
    }
  },
  async mounted() {
    // To fetch data for my attendance
    this.endDate = moment().format('YYYY-MM-DD')
    this.startDate = moment().subtract(9, 'days').format('YYYY-MM-DD')

    let param = { startDate: this.startDate, endDate: this.endDate }
    await this.fetchAttendance(param).then((result) => {
      this.dataMyAttendance = result
    })

    // if(this.currentUser.timezone){
    //   moment.tz.setDefault(this.currentUser.timezone)
    // }else{
    //   moment.tz.setDefault()
    // }

    // To get all team member name
    await this.fetchTeamMember().then((result) => {
      this.allMemberData = result
    })
    this.allMemberData = this.allMemberData.map((el) => el.fullName)
    this.teamMemberDataName = [
      ...this.teamMemberDataName,
      ...this.allMemberData,
    ]
  },
  methods: {
    ...mapActions({
      fetchAttendance: 'attendance/fetchAttendance',
      fetchMyTeamAttendance: 'attendance/fetchMyTeamAttendance',
      fetchTeamAttendanceHistory: 'attendance/fetchTeamAttendanceHistory',
      fetchTeamMember: 'attendance/fetchTeamMember',
    }),

    async openModal(row) {
      this.selectedAttendance = row
      this.selectedUserId = this.currentUser.id
      this.selectedUserName = this.currentUser.fullName
      this.isComponentModalActive = true
      this.showRow = row
      this.showDetail = row.data
    },

    formatPosition(index) {
      let markers = []
      // make sure this is not null
      if (
        this.showDetail[index].locationLatIn &&
        this.showDetail[index].locationLongIn
      ) {
        let marker = {
          lat: +this.showDetail[index].locationLatIn,
          lng: +this.showDetail[index].locationLongIn,
        }
        markers.push(marker)
      }
      if (
        this.showDetail[index].locationLatOut &&
        this.showDetail[index].locationLongOut
      ) {
        let marker = {
          lat: +this.showDetail[index].locationLatOut,
          lng: +this.showDetail[index].locationLongOut,
        }
        markers.push(marker)
      }

      return markers
    },

    closeSidebar() {
      this.isComponentModalActive = false
    },

    formatDate(str, format = 'D MMM') {
      const d = new Date(str)
      return moment(d).format(format)
    },

    formatTime(str) {
      return moment(moment.utc(str).toDate()).format('HH:mm')
    },

    formatTimeHour(str) {
      return moment(moment.utc(str).toDate()).format('HH')
    },

    formatTimeMinute(str) {
      return moment(moment.utc(str).toDate()).format('mm')
    },

    formatHeaderDate(date) {
      return moment(date).format('DD')
    },

    formatHeaderDay(date) {
      return moment(date).format('dddd')
    },

    getTimeRange(start, end) {
      if (!end) {
        return `${this.formatTime(start)} - On progress`
      }

      return `${this.formatTime(start)} - ${this.formatTime(end)}`
    },

    convertTimeToDuration(start, end) {
      const _startingTime = moment(start)
      let _endingTime
      if (end) {
        _endingTime = moment(end)
      } else {
        _endingTime = moment()
      }
      const duration = moment.duration(_endingTime.diff(_startingTime))
      const mins = duration.asMinutes()

      if (mins < 60 && mins >= 40) {
        return `${mins.toFixed()}m`
      }
      // else if (mins < 40) {
      //   return ''
      // }
      else {
        let _h = Math.floor(mins / 60)
        let _m = mins % 60

        return `${_h}h ${_m > 0 ? +_m.toFixed() + 'm' : ''}`
      }
    },

    toggleView(bool) {
      this.isViewedAsTable = bool
      this.closeSidebar()
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
    }),
    attendanceList() {
      return this.$store.state.attendanceDummy.attendanceList
    },

    getRangeDateTitle() {
      let start, end
      if (this.selectedDates.length > 0) {
        start = moment(this.selectedDates[0]).format('DD MMM YYYY').split(' ')
        end = moment(this.selectedDates[1]).format('DD MMM YYYY').split(' ')
      } else {
        start = moment(this.startDate).format('DD MMM YYYY').split(' ')
        end = moment(this.endDate).format('DD MMM YYYY').split(' ')
      }
      if (
        start[1].toLowerCase() === end[1].toLowerCase() &&
        start[2] === end[2]
      ) {
        return `${start[1]} ${+start[0]} - ${+end[0]}, ${start[2]}`
      } else {
        return `${+start[0]} ${start[1]} ${start[2]} - ${+end[0]} ${end[1]} ${
          end[2]
        }`
      }
    },

    filterTeamMemberDataName() {
      return this.teamMemberDataName.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.teamMemberName.toLowerCase()) >= 0
        )
      })
    },
  },
  watch: {
    async selectedDates() {
      // for table view
      const start = moment(this.selectedDates[0]).format('DD MMM YYYY')
      const end = moment(this.selectedDates[1]).format('DD MMM YYYY')

      let param = { startDate: start, endDate: end }
      await this.fetchAttendance(param).then((result) => {
        this.dataMyAttendance = result
      })
    },

    async selectedDateForWeek() {
      // for time grid view
      this.startWeek = moment(this.selectedDateForWeek)
        .startOf('isoWeek')
        .format('YYYY-MM-DD')
      this.endWeek = moment(this.selectedDateForWeek)
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.gotoDate(this.selectedDateForWeek)
      this.combinedDateForWeek = `${this.startWeek}|${this.endWeek}`
    },

    async combinedDateForWeek() {
      let combinedAllEvents
      if (this.teamMemberName === 'My Data') {
        // fetching data for my attendance
        let param = { startDate: this.startWeek, endDate: this.endWeek }
        this.oneWeekData = await this.fetchAttendance(param)

        const allEvents = this.oneWeekData.map((el) => el.data)
        combinedAllEvents = allEvents.flat()
      } else {
        // fetching data for team attendance
        let param = { startDate: this.startWeek, endDate: this.endWeek }
        await this.fetchTeamAttendanceHistory(param).then((result) => {
          this.teamMemberData = result
        })

        this.singleMemberData = this.teamMemberData.filter(
          (el) => el.name === this.teamMemberName
        )
        const rawEvents = this.singleMemberData[0].data.map((el) => el.data)
        combinedAllEvents = rawEvents.flat()
      }

      const events = combinedAllEvents.map((el) => ({
        title: 'Working Hour',
        start: moment.utc(el.startTime).toISOString(true),
        end: moment.utc(el.endTime).toISOString(true),
        location: el.locationIn,
        status: el.isRemote,
      }))
      this.calendarOptions.events = events
    },

    async teamMemberName(name) {
      let singleMemberEvents
      if (name === 'My Data') {
        // fetching data for my attendance
        let param = { combinedDate: this.combinedDateForWeek }
        this.oneWeekData = await this.fetchAttendance(param)

        const allEvents = this.oneWeekData.map((el) => el.data)
        singleMemberEvents = allEvents.flat()
      } else {
        // fetching data for team attendance
        await this.fetchTeamAttendanceHistory(this.combinedDateForWeek).then(
          (result) => {
            this.teamMemberData = result
          }
        )

        this.singleMemberData = this.teamMemberData.filter(
          (el) => el.name === name
        )
        const rawEvents =
          this.singleMemberData[0] &&
          this.singleMemberData[0].data.map((el) => el.data)
        singleMemberEvents = rawEvents && rawEvents.flat()
      }

      const events =
        singleMemberEvents &&
        singleMemberEvents.map((el) => ({
          title: 'Working Hour',
          start: moment.utc(el.startTime).toISOString(true),
          end: moment.utc(el.endTime).toISOString(true),
          location: el.locationIn,
          status: el.isRemote,
        }))
      this.calendarOptions.events = events
    },
  },
}
</script>
